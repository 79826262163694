import React from 'react';
import cx from 'classnames';
import styles from './input.module.css';

export const Checkbox = ({
  label = '',
  input_id = '',
  checked = false,
  onClick = '',
  checkboxClass,
  labelClass
}) => {
  const defaultCheckboxClass = `${styles.gmCheckbox} ${checked ? styles.checkboxOn : ''} `;

  return (
    <div className={styles.checkboxWrapper} onClick={onClick}>
      <div className={cx(defaultCheckboxClass, checkboxClass)}></div>
      <label className={labelClass} htmlFor={input_id}>
        {label}
      </label>
    </div>
  );
};
