import { createContext } from 'react';
import { useSelector } from 'react-redux';
import { urls } from 'services/_url';
import { io } from 'socket.io-client';

export const SocketContext = createContext();
export const useSocket = () => {
  const { id: tenant_id } = useSelector((state) => state.user_data);

  const socket = io(urls.notification, {
    query: { tenant_id }
  });

  return { socket };
};
