import { GridColumn, GridRow } from 'app/layouts/grid';
import React from 'react';
import { TenantProfileSetting } from './tenant/index';
import { UserProfileSetting } from './user';

export const ProfileSettings = () => {
  return (
    <GridRow
      tabletStyles={{ gridTemplateColumns: 'repeat(1,1fr)', gap: 0 }}
      mobileStyles={{ gap: 0 }}
    >
      <GridColumn>
        <UserProfileSetting />
      </GridColumn>
      <GridColumn span={2}>
        <TenantProfileSetting />
      </GridColumn>
    </GridRow>
  );
};
