export const table_config = {
  actions: {
    single: (tutorial) => [{ label: 'Open', value: 'open' }]
  },
  allow_bulk_action: true,
  css: {},
  fields: [
    {
      title: 'Title',
      key: 'title',
      searchable: true,
      isTitle: true
    },
    {
      title: 'Description',
      key: 'description',
      searchable: true,
      isTagline: true
    },
    {
      title: 'Date added',
      key: 'created_on',
      formatter: (value) => {
        return value ? new Date(value).toDateString() : '';
      },
      isMetadata: true
    }
  ],
  items: [],
  // search_key: 'name',
  search_text: ''
};
