import React, { useEffect, useState } from 'react';
import styles from './payment-method.module.css';
import { BasicInfoSection } from 'app/shared/info-section/basic';
import card_icon from 'assets/svg/card.svg';
import palpal_icon from 'assets/svg/paypal.svg';
import { useBillingService } from 'hooks/billing/billing';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { Button } from 'app/shared/button';
import { prepareCheckoutConfig } from 'app/member/modules/wallet/helper';
import { useSelector } from 'react-redux';
import { useCurrencyService } from 'hooks/currency';
import { usePaymentService } from 'hooks/payment';
import { Spacer } from 'app/layouts/generic';
import { Icon } from 'assets/icons';
import { toast } from 'react-toastify';
import { useTenantService } from 'hooks/iam/tenant';

export const PaymentMethod = () => {
  const user = useSelector((state) => state.user_data);
  const { fetchCurrencies } = useCurrencyService();
  const { fetchTenant } = useTenantService();
  const { createPayment } = usePaymentService();
  const [currency, setCurrency] = useState({});
  const { fetchBillingInfos } = useBillingService();
  const [billing_info, setBillingInfo] = useState({});

  useEffect(() => {
    fetchBillingInfos().then(({ billing_infos, error }) => {
      if (error) return toast.error(error);
      setBillingInfo(billing_infos[0]);
    });
  }, []);

  useEffect(() => {
    fetchCurrencies().then(({ currencies, error }) => {
      if (error) {
        toast.error('Unable to fetch currencies at this time.');
        return;
      }
      if (currencies.length) {
        const mapped_currency = currencies.map((curr) => {
          return {
            label: curr.code,
            value: curr.code,
            country_code: curr.country_code,
            exchange_rate: curr.exchange_rate
          };
        });
        setCurrency(
          mapped_currency.find((option) => option.country_code === billing_info?.country)
        );
      }
    });
  }, [billing_info]);

  const CardDetails = ({ data }) => {
    return (
      <>
        {data.payments?.provider === 'card' ? (
          <GridRow num_of_columns={6}>
            <GridColumn span={2}>
              <img src={card_icon} alt="card logo" className={styles.img} />
            </GridColumn>
            <GridColumn span={4}>
              <div
                className={styles.payment_info}
              >{`CARD ENDING IN ${data.payments?.details.last_4digits}`}</div>
              <div
                className={styles.payment_sub_info}
              >{`Expires on ${data.payments?.details.expiry}`}</div>
            </GridColumn>
          </GridRow>
        ) : (
          <GridRow>
            <GridColumn>
              <img src={palpal_icon} alt="Paypal logo" className={styles.img} />
            </GridColumn>
            <GridColumn>
              <div className={styles.payment_info}>{data.payments?.details.email_address}</div>
              <div
                className={styles.payment_sub_info}
              >{`${data.payments?.details.name.given_name} ${data.payments?.details.name.surname}`}</div>
            </GridColumn>
          </GridRow>
        )}
      </>
    );
  };

  const handlePaymentMethod = async () => {
    const { tenant } = await fetchTenant(user.tenant_id);
    if (!tenant || !tenant.id) return toast.error('Unable to process tenant data');
    const TEST_FEE = '10';
    const config = prepareCheckoutConfig(tenant, TEST_FEE, currency);
    const data = {
      currency: currency.value,
      conversion_rate: currency.exchange_rate,
      amount: TEST_FEE,
      tenant_id: user.id,
      tx_ref: config.tx_ref,
      reason: 'card-fee'
    };

    const payment_record = await createPayment({ data });
    if (payment_record) {
      window.FlutterwaveCheckout(config);
    }
  };

  return (
    <>
      <BasicInfoSection
        title="Payment Methods"
        icon={<Icon name="edit" onClick={handlePaymentMethod} />}
      >
        {billing_info?.payments?.details ? (
          <CardDetails data={billing_info} />
        ) : (
          <div className={styles.message}> No Payment method available </div>
        )}
        <Spacer multiple={3} />
        <Button onClick={handlePaymentMethod} text="Add Payment Method" />
      </BasicInfoSection>
    </>
  );
};
