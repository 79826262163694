export const determineRules = (datatype = 'string') => {
  const rules_by_type = {
    boolean: [
      { label: 'Is True', value: 'true' },
      { label: 'Is False', value: 'false' }
    ],
    date: [
      { label: 'On', value: '$on' },
      { label: 'Before', value: '$before' },
      { label: 'After', value: '$after' },
      { label: 'Min. time ago', value: '$min' },
      { label: 'Max. time ago', value: '$max' }
    ],
    number: [
      { label: 'Equal to', value: '$eq' },
      { label: 'Not equal to', value: '$ne' },
      { label: 'Greater than', value: '$gt' },
      { label: 'Less than', value: '$lt' }
    ],
    string: [
      { label: 'Contains', value: '$in' },
      { label: 'Starts With', value: '$start' },
      { label: 'Ends With', value: '$end' },
      { label: 'Matches Exactly', value: '$exact' },
      { label: 'Does Not Contain', value: '$not_in' },
      { label: 'Does Not Start With', value: '$not_start' },
      { label: 'Does Not End With', value: '$not_end' },
      { label: 'Does Not Match Exactly', value: '$not_exact' }
    ]
  };

  return rules_by_type[datatype];
};
