import React from 'react';
import styles from './checkout.module.css';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { Spacer } from 'app/layouts/generic';
import { Button } from 'app/shared/button';
import { prepareCheckoutConfig } from 'app/member/modules/wallet/helper';
import { useSelector } from 'react-redux';

import { usePaymentService } from 'hooks/payment';
import { useTenantService } from 'hooks/iam/tenant';
import { toast } from 'react-toastify';
import { toReadableNumber } from 'app/shared/utils/number';

export const CheckOut = ({ closeModal, plan_info = {} }) => {
  const {
    amount_to_pay,
    cost,
    currency,
    discount,
    discount_rate,
    discounted_price,
    plan,
    tax,
    tax_rate
  } = plan_info;

  const { tenant_id } = useSelector((state) => state.user_data);

  const { createPayment } = usePaymentService();
  const { fetchTenant } = useTenantService();

  const submit = async () => {
    const { tenant } = await fetchTenant(tenant_id);
    if (!tenant || !tenant.id) return toast.error('Unable to process tenant data');
    const config = prepareCheckoutConfig(tenant, amount_to_pay, currency);
    const data = {
      currency: currency.value,
      conversion_rate: currency.exchange_rate,
      amount: amount_to_pay,
      tenant_id,
      tx_ref: config.tx_ref,
      reason: 'subscription',
      metadata: plan_info
    };
    const payment_record = await createPayment({ data });
    if (payment_record) {
      window.FlutterwaveCheckout(config);
      closeModal();
    }
  };

  return (
    <div className={styles.wrapper}>
      <GridRow num_of_columns={1}>
        <GridColumn>
          <div className={styles.details}>
            <div className={styles.summaryTitle}>
              <GridRow num_of_columns={2}>
                <GridColumn>Order Summary</GridColumn>
                <GridColumn>
                  <div style={{ textAlign: 'right' }}>{currency?.value}</div>
                </GridColumn>
              </GridRow>
            </div>
            <Spacer multiple={2} />
            <div className={styles.breakDown}>
              <GridRow num_of_columns={2}>
                <GridColumn>Subscription: {plan?.title}</GridColumn>
                <GridColumn>
                  <div style={{ textAlign: 'right' }}>{toReadableNumber(cost)}</div>
                </GridColumn>
              </GridRow>
              <GridRow num_of_columns={2}>
                <GridColumn>Discount({discount_rate}%)</GridColumn>
                <GridColumn>
                  <div style={{ textAlign: 'right' }}>- {toReadableNumber(discount)}</div>
                </GridColumn>
              </GridRow>
              <Spacer multiple={4} />
              <b>
                <GridRow num_of_columns={2}>
                  <GridColumn>Subtotal</GridColumn>
                  <GridColumn>
                    <div style={{ textAlign: 'right' }}>{toReadableNumber(discounted_price)}</div>
                  </GridColumn>
                </GridRow>
              </b>
              <GridRow num_of_columns={2}>
                <GridColumn>VAT({tax_rate}%)</GridColumn>
                <GridColumn>
                  <div style={{ textAlign: 'right' }}>{toReadableNumber(tax)}</div>
                </GridColumn>
              </GridRow>
              <Spacer multiple={4} />
              <b>
                <GridRow num_of_columns={2}>
                  <GridColumn>Total</GridColumn>
                  <GridColumn>
                    <div style={{ textAlign: 'right' }}>{toReadableNumber(amount_to_pay)}</div>
                  </GridColumn>
                </GridRow>
              </b>
            </div>
          </div>
        </GridColumn>
      </GridRow>
      <Spacer multiple={5} />
      <p>
        By completing payment, you agree to
        <ul>
          <li>
            our <a href="#">terms</a> and <a href="#">privacy policy</a>
          </li>
          <li>automatically renew your subscription</li>
        </ul>
      </p>
      <Spacer multiple={2} />
      <GridRow num_of_columns={1}>
        <GridColumn>
          <Button text="Complete Payment" onClick={submit} />
        </GridColumn>
      </GridRow>
    </div>
  );
};
