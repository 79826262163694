/** */
import config from '../../../../config';

export const prepareCheckoutConfig = (tenant, amount, currency) => ({
  currency: currency.code,
  amount,
  public_key: config.fl_key,
  tx_ref: `GM-${tenant.id}-${tenant._id}-${amount}-${Date.now()}`,
  country: currency.country_code,
  payment_options: 1,
  redirect_url: window.location.href,
  meta: {
    tenant_id: tenant.id,
    tenant_hash: tenant._id
  },
  customer: {
    email: tenant?.emails[0],
    phone_number: tenant.phone_number,
    name: tenant.name
  },
  callback: function (data) {
    console.log(data);
  },
  onclose: function () {
    // close modal
  },
  customizations: {
    title: 'Go-Mailer',
    description: 'Wallet Top-Up',
    logo: 'https://go-mailer.com/static/media/logo-sm.171d9bbe.svg'
  }
});
