/** */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'app/shared/button';
import { SimpleInput } from 'app/shared/input';
import { InfoBanner } from 'app/shared/info-banner';
import { useWallet } from 'hooks/wallet';
import styles from './wallet.module.css';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { Spacer } from 'app/layouts/generic';
import { DetailItem } from 'app/shared/info-section/item';
import useValidator from 'hooks/validator';
import { bvnConfig } from 'app/shared/utils/form/validation/bvn';

const BvnForm = ({ closeModal }) => {
  const { createVirtualAccount } = useWallet();
  const user = useSelector((state) => state.user_data);
  const { errors, validateField, form_is_valid } = useValidator(bvnConfig);

  const [form_data, setFormData] = useState({});
  const [saving, setSaving] = useState(false);

  const handleFieldChange = (key, value) => {
    validateField(key, value);
    setFormData((data) => ({ ...data, [key]: value }));
  };

  const submit = async () => {
    const data = {
      ...form_data,
      tenant_id: user.tenant_id,
      user
    };

    setSaving(() => true);
    const response = await createVirtualAccount(data);

    setSaving(() => false);
    if (response) closeModal();
  };

  return (
    <form className={styles.topupForm}>
      <GridRow num_of_columns={1}>
        <GridColumn>
          <InfoBanner message="Per regulations, your BVN is required to create a virtual account" />
        </GridColumn>
        <Spacer />
      </GridRow>
      <GridRow num_of_columns={2}>
        <GridColumn>
          <DetailItem title="First Name" required>
            <SimpleInput
              className="gm-input"
              value={form_data.firstname}
              onInput={(e) => handleFieldChange('firstname', e)}
              error={errors.firstname}
            />
          </DetailItem>
        </GridColumn>
        <GridColumn>
          <DetailItem title="Last Name" required>
            <SimpleInput
              className="gm-input"
              value={form_data.lastname}
              onInput={(e) => handleFieldChange('lastname', e)}
              error={errors.lastname}
            />
          </DetailItem>
        </GridColumn>
      </GridRow>
      <GridRow num_of_columns={2}>
        <GridColumn>
          <DetailItem title="BVN" required>
            <SimpleInput
              className="gm-input"
              type="number"
              value={form_data.bvn}
              onInput={(e) => handleFieldChange('bvn', e)}
              error={errors.bvn}
            />
          </DetailItem>
        </GridColumn>
        <GridColumn>
          <DetailItem title="BVN Phone number" required>
            <SimpleInput
              className="gm-input"
              value={form_data.phonenumber}
              onInput={(e) => handleFieldChange('phonenumber', e)}
              error={errors.phonenumber}
            />
          </DetailItem>
        </GridColumn>
      </GridRow>
      <GridRow num_of_columns={3}>
        <GridColumn />
        <GridColumn />
        <GridColumn>
          <Button text="Create Account" disabled={saving || !form_is_valid} onClick={submit} />
        </GridColumn>
      </GridRow>
    </form>
  );
};

export default BvnForm;
