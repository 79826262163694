/** */
import { AutomationProvider } from 'contexts/automation';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'app/hooks/query';
import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';

import { useSocket, SocketContext } from 'app/hooks/socket';
import * as PaymentService from 'services/payment';
import * as NotificationService from 'services/notification';
import * as IamService from 'services/iam';
import { addDataToStore } from 'store/actions/user-data';
import { useFeatureFlag } from 'hooks/go-flags';
import { addOneFlagToStore } from 'store/actions/flag';

import SideNav from './side-nav/side-nav';
import { EditorLayout } from './editors';
import { ModuleLayout } from './modules';
import { addMetadata } from 'store/actions/metadata';
import './member.css';
import { PreOrganizationModule } from './orgamization';
import { PermissionsContext, PermissionsProvider } from 'contexts/permissions';
import Spinner from 'app/shared/spinners/spinner-50/spinner-50';

const MemberArea = () => {
  const params = useQuery();
  const history = useNavigate();
  const dispatch = useDispatch();
  const { socket } = useSocket();
  const { isFeatureAllowed } = useFeatureFlag();
  const { tenant_id, token, memberships } = useSelector((state) => state.user_data);

  const [open_menu_tray, setOpenMenuTray] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    socket.on('notification', (notification) => {
      const { message } = notification;
      setNotifications((prev) => [notification, ...prev]);
      toast.info(message);
    });

    IamService.tokenVerification({ token }).then((response) => {
      const { error } = response;
      if (error === 'Unauthorized') {
        dispatch(addDataToStore({}));
        history('/login');
      }
    });

    NotificationService.read({
      token,
      query_string: 'sort_by=-created_on'
    })
      .then((response) => {
        const {
          payload: { data },
          error
        } = response;
        if (error) return;

        setNotifications(data.filter((n) => n.tenant_id === tenant_id));
      })
      .catch(() => {
        setNotifications([]);
      })
      .finally(() => setIsLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useMemo(() => {
    if (!Object.keys(params).length) return;

    PaymentService.verify({ data: params, token }).then(({ error }) => {
      if (error) {
        return;
      }
      toast.success('Transaction successful');
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.tx_ref]);

  useEffect(() => {
    const acceptance_token = window.localStorage.getItem('acceptance');
    const has_membership = (memberships || []).some((doc) => doc.status === 'active');
    if (!has_membership && !acceptance_token) {
      history('/organizations');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setMobileFlag();
    window.addEventListener('resize', () => {
      setMobileFlag();
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    isFeatureAllowed('Feature.EmailAutomation', { tenant_id }).then(({ is_permitted }) => {
      dispatch(addOneFlagToStore({ id: 'email_automation', is_permitted }));
    });

    isFeatureAllowed('Feature.ContactUploads', { tenant_id }).then(({ is_permitted }) => {
      dispatch(addOneFlagToStore({ id: 'contact_upload', is_permitted }));
    });

    isFeatureAllowed('Feature.EmailFootnote', { tenant_id }).then(({ is_permitted }) => {
      dispatch(addOneFlagToStore({ id: 'email_footnote', is_permitted }));
    });

    isFeatureAllowed('Feature.Billing', { tenant_id }).then(({ is_permitted }) => {
      dispatch(addOneFlagToStore({ id: 'billing', is_permitted }));
    });

    isFeatureAllowed('Feature.UserManagement', { tenant_id }).then(({ is_permitted }) => {
      dispatch(addOneFlagToStore({ id: 'user_management', is_permitted }));
    });
  }, [dispatch, isFeatureAllowed, tenant_id]);

  const setMobileFlag = () => {
    if (window.screen.width < 768) {
      dispatch(addMetadata({ is_mobile_view: true }));
    } else dispatch(addMetadata({ is_mobile_view: false }));
  };

  return (
    <SocketContext.Provider value={{ socket, notifications, isLoading }}>
      <PermissionsProvider>
        <AutomationProvider>
          <div className="member-area-wrapper">
            <section className="app-body">
              <Routes>
                <Route path="organizations/*" element={<PreOrganizationModule />} />
                <Route
                  path="/*"
                  element={
                    <MemberLayout
                      open_menu_tray={open_menu_tray}
                      setOpenMenuTray={setOpenMenuTray}
                    />
                  }
                />
              </Routes>
            </section>
          </div>
        </AutomationProvider>
      </PermissionsProvider>
    </SocketContext.Provider>
  );
};

const MemberLayout = ({ open_menu_tray, setOpenMenuTray }) => {
  const { email_automation, user_management } = useSelector((state) => state.flags);
  const { is_loading: is_loading_permissions, permission_error } = useContext(PermissionsContext);

  const spinnerLayoutStyles = {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  };

  return (
    <>
      {!email_automation || !user_management || is_loading_permissions ? (
        <div style={spinnerLayoutStyles}>
          <Spinner />
        </div>
      ) : permission_error ? (
        <div style={spinnerLayoutStyles}>
          <Spinner reload={permission_error} />
        </div>
      ) : (
        <>
          <SideNav open_tray={open_menu_tray} onTrayClose={() => setOpenMenuTray(false)} />
          <Routes>
            <Route path="/editor/*" element={<EditorLayout />} />
            <Route path="/*" element={<ModuleLayout setOpenMenuTray={setOpenMenuTray} />} />
          </Routes>
        </>
      )}
    </>
  );
};

export default MemberArea;
