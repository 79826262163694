/** */
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addDataToStore } from 'store/actions/user-data';
import { Alert } from 'app/shared/form-alert';
import { Button } from 'app/shared/button';
import { IconInput, SimpleInput } from 'app/shared/input';
import { Checkbox } from 'app/shared/input/checkbox';
import styles from '../guest.module.css';
import { ReactComponent as Icon } from 'assets/icons/auth/LoginIcon.svg';
import { isEmailValid } from 'app/shared/utils/input';
import { generateUniqueId } from 'app/shared/utils/general';
import { useGuestService } from 'hooks/guest';

const LoginForm = () => {
  const { login } = useGuestService();
  const [alert, setAlert] = useState({});
  const [form_data, setFormData] = useState({ email: '', password: '' });
  const [loading, setLoading] = useState(false);
  const [show_password, setShowPassword] = useState(false);
  const [remember_me, setRememberMe] = useState(false);
  const [errors, setErrors] = useState({
    email: '',
    password: ''
  });

  const validateField = useCallback(
    (fieldName, value) => {
      let errorMessage = '';
      switch (fieldName) {
        case 'email':
          if (!value.trim()) {
            errorMessage = 'Email address is required.';
          } else if (!isEmailValid(value)) {
            errorMessage = 'Invalid email address.';
          }
          break;
        case 'password':
          if (!value.trim()) {
            errorMessage = 'Password is required.';
          } else if (value.length < 8) {
            errorMessage = 'Password must contain 8 or more characters.';
          }
          break;
        default:
          break;
      }
      setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: errorMessage }));
    },
    [errors]
  );

  const handleEmailChange = (value) => {
    setFormData((form_data) => ({ ...form_data, email: value }));
    validateField('email', value);
  };

  const handlePasswordChange = (value) => {
    setFormData((form_data) => ({ ...form_data, password: value }));
    validateField('password', value);
  };

  const dispatch = useDispatch();
  const history = useNavigate();

  const toggleRememberMe = () => setRememberMe((initialValue) => !initialValue);

  const submitForm = () => {
    if (disableSubmitButton) {
      validateField('email', form_data.email);
      validateField('password', form_data.password);
      return;
    }

    setLoading(true);
    login({ data: form_data })
      .then((response) => {
        const { error, payload } = response;
        if (error) {
          setAlert({ message: error, id: generateUniqueId() });
          return;
        }

        dispatch(addDataToStore({ ...payload, remember_me, logged_in_time: Date.now() }));
      })
      .finally(() => setLoading(false));
  };

  const toggleShowPassword = () => {
    setShowPassword(!show_password);
  };

  const isFormDisabled = (errors, values) => {
    const hasErrors = Object.values(errors).some((error) => error !== '');
    const isFieldsIncomplete = Object.values(values).some((value) => !value?.trim());
    return hasErrors || isFieldsIncomplete;
  };

  const disableSubmitButton = isFormDisabled(errors, {
    email: form_data?.email,
    password: form_data?.password
  });

  return (
    <div className={styles.authWrapper}>
      <div className={styles.iconContainer}>
        <Icon />
      </div>
      <div>
        <h1>Welcome back!</h1>
        <p>Please enter your login details</p>
        <form>
          <Alert {...alert} />
          <SimpleInput
            placeholder="Email"
            onInput={handleEmailChange}
            value={form_data.email}
            className={styles.formInput}
            autoFocus
            error={errors.email}
            wrapperClassName={styles.inputMargin}
          />
          <IconInput
            placeholder="Password"
            icon_name={show_password ? 'hide' : 'show'}
            type={show_password ? 'text' : 'password'}
            icon_position="right"
            onInput={handlePasswordChange}
            onIconClick={toggleShowPassword}
            value={form_data.password}
            wrapperClassName={styles.formInput}
            error={errors.password}
          />
          <div className={styles.formAction}>
            <div>
              <Checkbox
                checked={remember_me}
                input_id="remember_me"
                label="Remember me"
                onClick={toggleRememberMe}
                labelClass={styles.checkBoxLabel}
              />
            </div>
            <div className={styles.formText} onClick={() => history('/password/recovery')}>
              Forgot your password?
            </div>
          </div>
          <Button
            className={styles.formBtn}
            type="submit"
            onClick={() => submitForm()}
            text="Log In"
            loading={loading}
          />
          <div className={styles.cta}>
            <span>Need an account?</span>
            <button className={styles.actionBtn} onClick={() => history('/register')}>
              Create an account
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
