/** */
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import AutomationModule from './automation/_index';
import CampaignModule from './campaigns/_index';
import ContactModule from './contacts/_index';
import MailingListModule from './mailing-lists/_index';
import IntegrationModule from './integrations/_index';
import SettingsModule from './settings/_index';
import TransactionalModule from './transactionals/_index';
import MemberAreaHeader from './header/header';
import GMDashboard from './dashboard';
import { Spacer } from 'app/layouts/generic';
import useScreenSize from 'hooks/size';
import UsersModule from './users/_index';
import { PermissionsContext } from 'contexts/permissions';
import { TutorialModule } from './tutorials/_index';

export const ModuleLayout = ({ setOpenMenuTray }) => {
  const { permissions } = useContext(PermissionsContext);
  const { user_management } = useSelector((state) => state.flags);
  const { isDesktop } = useScreenSize();

  return (
    <section className="app-content">
      <>
        <MemberAreaHeader onHamburgerClick={() => setOpenMenuTray(true)} />
        <Spacer multiple={6} />
        <Routes>
          {permissions['campaign:view'] && (
            <Route path="campaigns/*" element={<CampaignModule />} />
          )}
          {permissions['automation:view'] && isDesktop && (
            <Route path="automations/*" element={<AutomationModule />} />
          )}
          {permissions['audience:view'] && (
            <Route path="audiences/*" element={<MailingListModule />} />
          )}
          {permissions['contact:view'] && isDesktop && (
            <Route path="contacts/*" element={<ContactModule />} />
          )}
          {permissions['integration:view'] && (
            <Route path="integrations/*" element={<IntegrationModule />} />
          )}
          <Route path="settings/*" element={<SettingsModule />} />
          <Route path="transactionals/*" element={<TransactionalModule />} />
          <Route path="tutorials/*" element={<TutorialModule />} />
          {user_management?.is_permitted && <Route path="users/*" element={<UsersModule />} />}
          <Route path="/*" element={<GMDashboard />} />
        </Routes>
      </>
    </section>
  );
};
