import { DatePicker } from '@arco-design/web-react';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { DetailItem } from 'app/shared/info-section/item';
import { SimpleInput } from 'app/shared/input';
import { MultiSelect } from 'app/shared/select';
import { Icon } from 'assets/icons';
import React, { useEffect, useMemo, useState } from 'react';
import styles from '../query.module.css';
import { determineRules } from './helper';

const DATA_TYPES = [
  { label: 'Text', value: 'string' },
  { label: 'Boolean', value: 'boolean' },
  { label: 'Date', value: 'date' },
  { label: 'Number', value: 'number' }
];

const CYCLES = [
  { label: 'Month(s)', value: 'month' },
  { label: 'Year(s)', value: 'year' }
];

const DateInput = ({ value, rule, onChange }) => {
  const [show_picker, setShowPicker] = useState(true);
  const [selected_date, setSelectedDate] = useState('');
  const [duration, setDuration] = useState(1);
  const [cycle, setCycle] = useState(CYCLES[0]);

  useEffect(() => {
    if (!value) return;
    if (value.includes(':')) {
      const [dur, cyc] = value.split(':');
      setDuration(dur);
      setCycle(CYCLES.find((cycle) => cycle.value === cyc));
    } else {
      setSelectedDate(value);
    }
  }, [value]);

  useEffect(() => {
    setShowPicker(!['$min', '$max'].includes(rule));
  }, [rule]);

  useMemo(() => {
    if (!duration || !cycle) return;
    onChange(`${duration}:${cycle.value}`);
  }, [duration, cycle.value]);

  useMemo(() => {
    onChange(selected_date);
  }, [selected_date]);

  return (
    <>
      {show_picker ? (
        <DetailItem title="Select date">
          <DatePicker
            className={styles.datepicker}
            onChange={setSelectedDate}
            value={selected_date}
          />
        </DetailItem>
      ) : (
        <GridRow num_of_columns={2}>
          <GridColumn>
            <DetailItem title="duration">
              <SimpleInput type="number" min={1} value={duration} onInput={setDuration} />
            </DetailItem>
          </GridColumn>
          <GridColumn>
            <DetailItem title="cycle">
              <MultiSelect options={CYCLES} onChange={setCycle} value={cycle} />
            </DetailItem>
          </GridColumn>
        </GridRow>
      )}
    </>
  );
};

export const QueryRule = ({
  data = {},
  index = 0,
  properties = [],
  onChange = () => {},
  onDelete = () => {}
}) => {
  const [key, setKey] = useState(data.key);
  const [needle, setNeedle] = useState('');
  const [rules, setRules] = useState([]);
  const [selected_datatype, setSelectedDatatype] = useState({});
  const [selected_property, setSelectedProperty] = useState({});
  const [selected_rule, setSelectedRule] = useState({});

  useEffect(() => {
    setKey(() => data.key);
  }, []);

  useEffect(() => {
    const possible_rules = determineRules(selected_datatype?.value);
    const default_rule = possible_rules[0];

    setRules(() => possible_rules);
    setSelectedRule(() => default_rule);
    setNeedle(() => '');
  }, [selected_datatype]);

  useEffect(() => {
    if (selected_datatype?.value !== 'boolean' || !selected_rule?.value) return;
    setNeedle(selected_rule.value);
  }, [selected_datatype, selected_rule]);

  useEffect(() => {
    handleQueryChange(selected_property, selected_rule, needle);
  }, [index, selected_property, selected_rule, needle]);

  useMemo(() => {
    if (!data || !Object.keys(data).length) return;
    const possible_rules = determineRules(data.datatype);

    setSelectedDatatype(() =>
      DATA_TYPES.find((type) => type.value === data.datatype || type.value === 'string')
    );
    setSelectedProperty(() => properties.find((prop) => prop.value === data.property));
    setSelectedRule(() => possible_rules.find((rule) => rule.value === data.rule));
    setNeedle(() => data.needle);
  }, [properties, data.property, data.datatype, data.rule, data.needle]);

  const handleQueryChange = (selected_property, selected_rule, needle) => {
    if (!selected_property || !selected_rule || !needle) return;
    const query = {
      key,
      datatype: selected_datatype?.value,
      property: selected_property?.value,
      rule: selected_rule?.value,
      kind: selected_property?.kind,
      needle
    };

    onChange(query);
  };

  const is_needle_disabled = (datatype) => {
    return datatype === 'boolean';
  };

  return (
    <GridRow num_of_columns={5}>
      <GridColumn>
        <DetailItem title="property">
          <MultiSelect
            options={properties}
            value={selected_property}
            onChange={setSelectedProperty}
          />
        </DetailItem>
      </GridColumn>
      <GridColumn>
        <DetailItem title="data type">
          <MultiSelect
            options={DATA_TYPES}
            value={selected_datatype}
            onChange={setSelectedDatatype}
          />
        </DetailItem>
      </GridColumn>
      <GridColumn>
        <DetailItem title="rule">
          <MultiSelect
            options={rules}
            value={selected_rule}
            onChange={setSelectedRule}
            isSorted={false}
          />
        </DetailItem>
      </GridColumn>
      <GridColumn span={2}>
        <GridRow num_of_columns={10}>
          <GridColumn span={9}>
            {selected_datatype?.value !== 'date' ? (
              <DetailItem title="value">
                <SimpleInput
                  type={selected_datatype?.value === 'number' ? 'number' : 'text'}
                  disabled={is_needle_disabled(selected_datatype?.value)}
                  value={needle}
                  onInput={setNeedle}
                />
              </DetailItem>
            ) : (
              <DateInput rule={selected_rule?.value} value={needle} onChange={setNeedle} />
            )}
          </GridColumn>
          <GridColumn>
            <div className={styles.icon}>
              <Icon name="trash" onClick={() => onDelete(key)} />
            </div>
          </GridColumn>
        </GridRow>
      </GridColumn>
    </GridRow>
  );
};
