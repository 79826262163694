export const refillWalletValidationConfig = {
  currency: {
    required: true
  },
  amount: {
    required: true,
    customValidator: (value) => {
      const result = { is_valid: false, message: '' };
      if (value && Number(value) < 100) {
        result.message = 'Amount must be greater than 100.';
      } else result.is_valid = true;
      return result;
    }
  }
};
