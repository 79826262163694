/** */
import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as PaymentService from 'services/payment';
import * as CurrencyService from 'services/currency';
import { prepareCheckoutConfig } from './helper';
import styles from './wallet.module.css';
import cx from 'classnames';
import { Button } from 'app/shared/button';
import { SimpleInput } from 'app/shared/input';
import { MultiSelect } from 'app/shared/select';
import { InfoBanner } from 'app/shared/info-banner';

import GmModal from 'app/shared/modal/modal';
import BvnForm from './bvn-form';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { DetailItem } from 'app/shared/info-section/item';
import useValidator from 'hooks/validator';
import { refillWalletValidationConfig } from 'app/shared/utils/form/validation/wallet';
import { useTenantService } from 'hooks/iam/tenant';
import { useTaxService } from 'hooks/billing/tax';

const TopupForm = ({ closeModal }) => {
  const user = useSelector((state) => state.user_data);
  const wallet_in_store = useSelector((state) => state.wallet);
  const { fetchTenant } = useTenantService();
  const { token } = user;

  const { fetchTaxes } = useTaxService();
  const [amount, setAmount] = useState(0);
  const [tax, setTax] = useState(0);
  const [taxes, setTaxes] = useState([]);
  const [virtual_account, setVirtualAccount] = useState(null);
  const [currencies, setCurrencies] = useState([]);
  const [selected_currency, setSelectedCurrency] = useState({});
  const [tenant, setTenant] = useState({});
  const [showCreateAccountModal, setShowCreateAccountModal] = useState(false);

  const { errors, validateField, form_is_valid } = useValidator(refillWalletValidationConfig);

  useEffect(() => {
    CurrencyService.read({ token }).then((response) => {
      const {
        payload: { data }
      } = response;

      if (data) {
        const mapped_currency = data.map((curr) => {
          return {
            ...curr,
            label: `${curr.name} (${curr.code})`,
            value: curr.code,
            exchange_rate: curr.exchange_rate,
            country_code: curr.country_code
          };
        });
        setCurrencies(mapped_currency);
        setSelectedCurrency(mapped_currency.find((option) => option.value === 'NGN'));
      }
    });

    fetchTenant(user.tenant_id).then(({ tenant }) => {
      setTenant(() => tenant);
    });
  }, [user.tenant_id]);

  useEffect(() => {
    validateField('currency', selected_currency);
  }, [selected_currency]);

  useMemo(() => {
    const { virtual_account: v_account } = wallet_in_store;
    const account = v_account && v_account.account_number ? v_account : null;
    setVirtualAccount(account);
  }, [wallet_in_store.tenant_id]);

  useEffect(() => {
    fetchTaxes().then(({ taxes, error }) => {
      if (error) return toast.error(error);
      setTaxes(taxes);
    });
  }, []);

  const handleCurrencyChange = (e) => {
    setSelectedCurrency(e);
    handleTaxChange(amount, e);
  };

  const handleAmountChange = (value) => {
    validateField('amount', value);
    setAmount(value);
    handleTaxChange(value, selected_currency);
  };

  const handleTaxChange = (amount, currency) => {
    const currency_value = currency || selected_currency;
    const { tax: tax_rate } = taxes.find((tax) => tax.currency === currency_value.value) || {
      tax: 0
    };
    const tax_value = Number((amount * Number(tax_rate)) / 100);
    setTax(tax_value);
  };

  const submit = async () => {
    const int_amount = Number(amount);
    if (int_amount <= 0) {
      toast.error('Enter a valid amount');
      return;
    }

    if (!tenant || !tenant.id) {
      return toast.error('Unable to load data. Please reload browser.');
    }

    const config = prepareCheckoutConfig(tenant, amount, selected_currency);
    const data = {
      currency: selected_currency.value,
      conversion_rate: selected_currency.exchange_rate,
      amount,
      tenant_id: tenant.id,
      tx_ref: config.tx_ref
    };
    const { error } = await PaymentService.create({ data, token });
    if (error) {
      toast.error('Could not initialize payment.');
      return;
    }

    window.FlutterwaveCheckout(config);
    closeModal();
  };
  const openCreateAccountModal = () => setShowCreateAccountModal(true);

  return (
    <>
      <form>
        {virtual_account && (
          <GridRow num_of_columns={2}>
            <GridColumn span={2}>
              <div style={{ marginBottom: '20px' }}>
                <InfoBanner
                  message={`You can top up your wallet (Naira only) by making a bank transfer to your virtual account:
              
                  Account Name: ${virtual_account.account_name}
                  Account Number: ${virtual_account.account_number}
                  Bank Name: ${virtual_account.bank_name}`}
                />
              </div>
            </GridColumn>
          </GridRow>
        )}
        <GridRow num_of_columns={3}>
          <GridColumn>
            <DetailItem title="Select Currency">
              <MultiSelect
                className="gm-input"
                options={currencies}
                onChange={handleCurrencyChange}
                sort_options_by="source"
                value={selected_currency}
                error={errors.currency}
              />
              <small className="pb-2 text-info">
                {selected_currency?.value !== 'NGN'
                  ? `Exchange rate: ₦${Number(selected_currency?.exchange_rate).toFixed(2)}`
                  : ''}
              </small>
            </DetailItem>
          </GridColumn>
          <GridColumn>
            <DetailItem title="Amount">
              <SimpleInput
                className="gm-input"
                type="number"
                value={amount}
                onInput={handleAmountChange}
                error={errors.amount}
              />
              <small className="pb-2 text-info">
                {amount > 0 && selected_currency?.value !== 'NGN'
                  ? `Your wallet would be topped up with ₦
            ${(Number(amount) * Number(selected_currency?.exchange_rate || 1)).toFixed(2)}
            `
                  : ''}
              </small>
            </DetailItem>
          </GridColumn>
          <GridColumn>
            <DetailItem title="TAX (7.5%)">
              <SimpleInput
                className="gm-input"
                type="number"
                value={tax}
                onInput={handleTaxChange}
                disabled
              />
            </DetailItem>
          </GridColumn>
        </GridRow>
        <GridRow num_of_columns={6}>
          <GridColumn span={`${virtual_account ? '4' : '2'}`}></GridColumn>
          {!virtual_account && (
            <GridColumn span={2}>
              <div
                className={cx(styles.virtualText, 'px-sm-0 virtual-text')}
                onClick={openCreateAccountModal}
              >
                Create a virtual Account
              </div>
            </GridColumn>
          )}
          <GridColumn span={2}>
            <Button text="Next" onClick={submit} disabled={!form_is_valid} />
          </GridColumn>
        </GridRow>
      </form>
      <div>
        {showCreateAccountModal && (
          <GmModal
            title="Create virtual account"
            show_title
            show_modal={showCreateAccountModal}
            onClose={() => setShowCreateAccountModal(false)}
          >
            <BvnForm closeModal={() => setShowCreateAccountModal(false)} />
          </GmModal>
        )}
      </div>
    </>
  );
};

export default TopupForm;
