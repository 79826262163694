import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as SubscriptionService from 'services/subscription';

export const useSubscriptionService = () => {
  const { token } = useSelector((store) => store.user_data);

  const cancelSubscription = async (id, options = {}) => {
    const { error, payload } = await SubscriptionService.update(id, {
      ...options,
      token
    });

    if (error) {
      toast.error('Unable to cancel subscription at this time.');
      return null;
    }
    toast.success('Subscription Cancelled Successfully');

    return payload;
  };

  const fetchSubscription = async (options = {}) => {
    const { error, payload } = await SubscriptionService.read({ ...options, token });
    if (error) {
      return { subscriptions: [], size: 0, error: 'Unable to fetch subscription at this time.' };
    }

    const {
      data: subscriptions,
      meta: { size }
    } = payload;
    return { subscriptions, size, error: null };
  };
  return { cancelSubscription, fetchSubscription };
};
