import React, { useState, useEffect } from 'react';
import { DetailItem } from 'app/shared/info-section/item';
import { BasicInfoSection } from 'app/shared/info-section/basic';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { Spacer } from 'app/layouts/generic';
import { Icon } from 'assets/icons';
import { useInvoiceService } from 'hooks/billing/invoice';
import { toDateString } from 'app/shared/utils/date';
import styles from './invoice.module.css';
import { toast } from 'react-toastify';

export const InvoiceList = () => {
  const { fetchInvoices } = useInvoiceService();
  const [invoices, setInvoices] = useState([]);
  const [visible_invoices, setVisibleInvoices] = useState(3);
  const [has_more, setHasMore] = useState(true);

  useEffect(() => {
    fetchInvoices().then(({ invoices, error }) => {
      if (error) return toast.error(error);
      const sorted_invoices = invoices
        .filter((invoice) => invoice.subscription_id)
        .sort((a, b) => new Date(b.created_on) - new Date(a.created_on));
      setHasMore(sorted_invoices.length > visible_invoices);
      setInvoices(sorted_invoices);
    });
  }, []);

  const loadMoreInvoices = () => {
    setVisibleInvoices((prevVisibleInvoices) => prevVisibleInvoices + 3);
    setHasMore(invoices.length >= visible_invoices);
  };

  const styleStatusBadge = (status) => {
    switch (status) {
      case 'paid':
        return <span className={styles.greenBadge}>{status}</span>;
      case 'unpaid':
        return <span className={styles.dangerBadge}>{status}</span>;
    }
  };

  const handleDownload = (link) => {
    if (!link) return toast.error('Error downloading Invoice.');
    window.open(link, '_blank');
  };

  return (
    <BasicInfoSection title="Invoices">
      {invoices.slice(0, visible_invoices).map((invoice, index) => (
        <GridRow key={index}>
          <GridColumn>
            <DetailItem title="DATE">{toDateString(invoice.due_date)}</DetailItem>
          </GridColumn>

          <GridColumn>
            <DetailItem title="STATUS">{styleStatusBadge(invoice.status)}</DetailItem>
          </GridColumn>
          <GridColumn>
            <DetailItem title="AMOUNT">{invoice.cost}</DetailItem>
          </GridColumn>
          <GridColumn>
            <DetailItem>
              <span style={{ display: 'flex', justifyContent: 'center' }}>
                <Icon
                  name="download"
                  onClick={() => {
                    handleDownload(invoice?.pdf_link);
                  }}
                />
              </span>
            </DetailItem>
          </GridColumn>
        </GridRow>
      ))}
      {has_more && (
        <a className={styles.readMore} onClick={loadMoreInvoices}>
          Read More
        </a>
      )}
      <Spacer multiple={2} />
    </BasicInfoSection>
  );
};
