import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import * as TutorialService from 'services/file/tutorial';

export const useTutorialService = () => {
  const { token } = useSelector((store) => store.user_data);

  const createTutorial = async (options = {}) => {
    const { error, payload: tutorial } = await TutorialService.create({
      ...options,
      token
    });

    if (error) {
      toast.error('Cannot create tutorial at this time');
      return { tutorial: {} };
    }

    toast.success('Tutorial created successfully.');
    return { tutorial };
  };

  const deleteTutorial = async (id, options = {}) => {
    const { error } = await TutorialService.deleteById(id, {
      ...options,
      token
    });

    if (error) {
      toast.error('Cannot delete tutorial at this time');
      return false;
    }

    toast.success('Tutorial deleted successfully.');
    return true;
  };

  const fetchTutorial = async (id, options = {}) => {
    const { error, payload } = await TutorialService.readById(id, { ...options, token });
    if (error) {
      toast.error('Unable to fetch tutorial at this time.');
      return { tutorial: {} };
    }

    return { tutorial: payload };
  };

  const fetchTutorials = async (options = {}) => {
    const { error, payload } = await TutorialService.read({ ...options, token });
    if (error) {
      return { tutorials: [], size: 0, error: 'Unable to fetch tutorials at this time.' };
    }

    const {
      data: tutorials,
      meta: { size }
    } = payload;
    return { tutorials, size, error: null };
  };

  const searchTutorials = async (keys, keyword, options = {}) => {
    const { error, payload } = await TutorialService.search(keys, keyword, { ...options, token });
    if (error) {
      toast.error('Unable to fetch tutorials at this time.');
      return { tutorials: [], size: 0 };
    }

    const {
      data: tutorials,
      meta: { size }
    } = payload;
    return { tutorials, size };
  };

  const updateTutorial = async (id, options = {}) => {
    const { error, payload: tutorial } = await TutorialService.updateById(id, {
      ...options,
      token
    });

    if (error) {
      toast.error('Cannot update tutorial at this time');
      return { tutorial: {} };
    }

    toast.success('Tutorial updated successfully.');
    return { tutorial };
  };

  return {
    createTutorial,
    deleteTutorial,
    fetchTutorial,
    fetchTutorials,
    searchTutorials,
    updateTutorial
  };
};
