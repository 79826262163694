export const bvnConfig = {
  firstname: {
    required: true,
    pattern: /\w+/
  },
  lastname: {
    required: true,
    pattern: /\w+/
  },
  bvn: {
    required: true,
    minLength: 11,
    maxLength: 11
  },
  phonenumber: {
    required: true,
    pattern: /\+*[\d]+/,
    minLength: 11,
    maxLength: 14
  }
};
