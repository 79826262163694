import { useSelector } from 'react-redux';
import * as GuestService from 'services/guest';

export const useGuestService = () => {
  const { token } = useSelector((store) => store.user_data);

  const activate = async (id) => {
    return await GuestService.activate(id);
  };

  const login = async (options = {}) => {
    return await GuestService.login(options);
  };

  const logOut = async (options = {}) => {
    return await GuestService.logOut({ token, ...options });
  };

  const recoverPassword = async (options = {}) => {
    return await GuestService.recoverPassword(options);
  };

  const resendActivationLink = async (email, options = {}) => {
    return GuestService.resendActivationLink(email);
  };

  const resetPassword = async (options = {}) => {
    return await GuestService.resetPassword(options);
  };

  const signup = async (options = {}) => {
    return await GuestService.signup(options);
  };

  return {
    activate,
    login,
    logOut,
    recoverPassword,
    resendActivationLink,
    resetPassword,
    signup
  };
};
