import { toast } from 'react-toastify';
import { urls } from 'services/_url';
import { useNavigate } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WebDatatable from 'app/shared/datatable/web/datatable';
import MobileDatatable from 'app/shared/datatable/mobile/datatable';
import ConfirmationDialog from 'app/shared/dialogs/confirmation';

import {
  addManyCampaignsToStore,
  addOneCampaignToStore,
  addCampaignSearchResultsToStore,
  removeOneCampaignFromStore
} from 'store/actions/campaign';
import { setPageTitle } from 'store/actions/header';
import { Button } from 'app/shared/button';
import { campaignCanBeCancelled, campaignCanBeEdited } from 'utilities/form';
import GmModal from 'app/shared/modal/modal';
import { OptionsModal } from 'app/shared/option-modal';
import styles from '../campaign.module.css';
import { PageContentWrapper } from 'app/layouts/wrapper/page-content';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { Spacer } from 'app/layouts/generic';
import { CampaignStatBar } from './statbar';
import { useCampaignService } from 'hooks/mailing/campaign';
import useScreenSize from 'hooks/size';
import { truncateText } from 'app/shared/utils/general';
import { useDraftService } from 'hooks/mailing/draft';
import { PermissionsContext } from 'contexts/permissions';
import { useMailingConfigService } from 'hooks/mailing/configuration';

const ListCampaigns = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { fetchCampaign, fetchCampaigns, searchCampaigns, updateCampaign } = useCampaignService();
  const { createDraft, deleteDraft } = useDraftService();
  const { fetchConfiguration } = useMailingConfigService();

  const { permissions } = useContext(PermissionsContext);
  const { token } = useSelector((state) => state.user_data);
  const { is_mobile_view } = useSelector((state) => state.metadata);
  const campaigns_in_store = useSelector((state) => state.campaigns);
  const { isMobile, isTablet } = useScreenSize();

  const [campaigns, setCampaigns] = useState([]);
  const [is_dkim_set, setIsDKIMSet] = useState(false);
  const [is_search_mode, setSearchMode] = useState(false);
  const [loading, setLoading] = useState(false);

  const [show_cancel_confirmation, setShowCancelConfirmation] = useState(false);
  const [show_creation_options, setShowCreationOptions] = useState(false);
  const [show_delete_confirmation, setShowDeleteConfirmation] = useState(false);
  const [show_test_campaigns, setShowTestCampaigns] = useState(false);
  const [campaign_to_delete, setCampaignToDelete] = useState(0);
  const [campaign_to_cancel, setCampaignToCancel] = useState({});
  const [total_campaigns, setTotalCampaigns] = useState(0);
  const { creationOptions } = useCampaignService();

  useEffect(() => {
    dispatch(setPageTitle([{ title: 'My Campaigns', path: '.' }]));
    fetchConfiguration().then(({ configurations: [config] }) => {
      if (!config) return;

      const { dkim } = config;
      if (dkim.key && dkim.selector) setIsDKIMSet(() => true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const parsed_campaigns = Object.values(campaigns_in_store).filter(
      (campaign) => campaign.is_test_campaign === show_test_campaigns
    );
    setCampaigns(() => parsed_campaigns);
  }, [campaigns_in_store]);

  useEffect(() => {
    handleDataRequest(0);
  }, [show_test_campaigns]);

  const config = {
    actions: {
      single: (campaign) => [
        {
          value: 'view',
          label: 'View'
        },
        {
          value: 'edit',
          label: 'Edit',
          hidden: !campaignCanBeEdited(campaign) || !permissions['campaign:update']
        },
        { label: 'Clone', value: 'duplicate' },
        {
          label: 'Cancel',
          value: 'cancel',
          hidden: campaign.status !== 'queued'
        },
        // { label: 'Resend to non-openers', value: 'retry', hidden: campaign.status !== 'processed' },
        {
          label: 'Delete',
          value: 'delete',
          hidden: campaign.status !== 'draft' || !permissions['campaign:delete']
        }
      ]
    },
    allow_bulk_action: true,
    css: {},
    fields: [
      {
        title: 'Campaign Name',
        key: 'name',
        isTitle: true,
        formatter: (value) => truncateText(value, 25) || ''
      },
      {
        title: 'Sender Name',
        key: 'sender_name',
        isTagline: true,
        formatter: (value) => value || 'N/A'
      },
      {
        title: 'Sender Email',
        key: 'sender_email',
        isTagline: true,
        formatter: (value) => {
          return truncateText(value, isTablet ? 25 : 35) || 'N/A';
        }
      },
      {
        title: 'Status',
        key: 'status',
        formatter: (value) => {
          switch (value) {
            case 'processed':
              return <span className={styles.greenBadge}>{value}</span>;
            case 'processing':
            case 'queued':
              return <span className={styles.infoBadge}>{value}</span>;
            case 'cancelled':
              return <span className={styles.dangerBadge}>{value}</span>;
            default:
              return <span className={styles.dangerBadge}>{value}</span>;
          }
        },
        isBadge: true
      },
      {
        title: 'Send date',
        key: 'schedule',
        formatter: (value) => {
          if (!value?.date) return '...';
          return new Date(value.date).toDateString();
        },
        isMetadata: true
      }
    ],
    is_search_mode,
    items: campaigns.sort((a, b) => {
      const a_date = a.status === 'draft' ? a.created_on : a.schedule?.date;
      const b_date = b.status === 'draft' ? b.created_on : b.schedule?.date;

      const parsed_a = new Date(a_date).toISOString().replace(/\.\d{3}Z/gi, '.000Z');
      const parsed_b = new Date(b_date).toISOString().replace(/\.\d{3}Z/gi, '.000Z');

      let difference = Date.parse(parsed_b) - Date.parse(parsed_a);
      if (difference === 0) {
        difference = b.id - a.id;
      }

      return difference;
    }),
    search_key: 'name',
    search_text: ''
  };

  const handleDeleteDraft = async (id) => {
    const result = await deleteDraft(id);
    if (!result) {
      toast.error('Could not delete draft.');
      return;
    }

    dispatch(removeOneCampaignFromStore(id));
    toast.success('Draft deleted successfully.');
  };

  const handleDeleteConfirmation = (permitted) => {
    if (permitted) {
      handleDeleteDraft(campaign_to_delete);
    }

    setCampaignToDelete(0);
    setShowDeleteConfirmation(false);
  };

  const cancelQueued = async (campaign) => {
    const { id } = campaign;
    const result = await updateCampaign(id, {
      data: { ...campaign, old_status: campaign.status, status: 'cancelled' }
    });
    if (!result) return;
    dispatch(addOneCampaignToStore({ ...campaign, status: 'cancelled' }));
  };

  const duplicate = async (campaign) => {
    toast.info(`cloning campaign: ${campaign.name}`);
    const { campaign: full_campaign } = await fetchCampaign(campaign.id);

    if (!full_campaign || !full_campaign.id) {
      return toast.error('Cloning failed. Please try again');
    }

    const new_data = {
      ...full_campaign,
      cost: '0.00',
      failed_sends_list: [],
      schedule: { ...full_campaign.schedule },
      name: `[DUPLICATE] ${full_campaign.name}`,
      status: 'draft',
      attachments: []
    };

    delete new_data.id;
    delete new_data._id;
    delete new_data.time_stamp;
    delete new_data.is_enqueued;
    delete new_data.created_on;
    delete new_data.updated_on;
    delete new_data.failed_sends_list;
    delete new_data.metadata;
    delete new_data.schedule.date;
    delete new_data.is_test_campaign;

    const draft = await createDraft({ data: new_data });
    if (!draft) {
      toast.error('Cloning failed.');
      return;
    }

    dispatch(addOneCampaignToStore(draft));
    toast.success('Cloning successful.');
  };

  const handleCancelConfirmation = (permitted) => {
    if (permitted) {
      cancelQueued(campaign_to_cancel);
    }

    setCampaignToCancel({});
    setShowCancelConfirmation(false);
  };

  const handleDatatableAction = (payload) => {
    const { name, type, data } = payload;

    if (type === 'single') {
      switch (name) {
        case 'edit':
          if (!campaignCanBeEdited(data)) {
            toast.warning('Cannot edit campaign.');
            break;
          }

          if (data.is_advanced) {
            window.location.href = `${urls.editor}/campaigns/${data.id}?t=${token}`;
            return;
          }

          history(`/editor/campaign/${data.id}`);
          break;
        case 'duplicate':
          duplicate(data);
          break;
        case 'delete':
          if (data.status !== 'draft') {
            toast.info(`Cannot delete ${data.status} campaigns.`);
            break;
          }

          setCampaignToDelete(data.id);
          setShowDeleteConfirmation(true);
          break;
        case 'cancel':
          if (data.status !== 'queued') {
            toast.info(`Cannot cancel ${data.status} campaigns.`);
            break;
          }

          if (!campaignCanBeCancelled(data.schedule.date)) {
            toast.info('Cannot cancel campaign created today.');
            break;
          }
          setCampaignToCancel(data);
          setShowCancelConfirmation(true);
          break;
        case 'retry':
          handleRetryCampaign(data);
          break;
        default:
          history(`/campaigns/${data.id}`);
      }
    }
  };

  const handleRetryCampaign = async (data) => {
    const { id } = data;
    toast.info('Resending Campaign...');
    const response = await updateCampaign(id, {
      data: { ...data, status: 'queued', is_enqueued: false }
    });
    if (!response) {
      toast.error('Unable to resend campaign.');
      return;
    }
    dispatch(addOneCampaignToStore({ ...data, status: 'queued' }));
    toast.success('Campaign queued successfully');
  };

  const handleItemClick = (payload) => {
    const { id } = payload;
    history(`/campaigns/${id}`);
  };

  const handleDataRequest = async (page, population = 50) => {
    try {
      setLoading(true);
      const test_campaign_query = show_test_campaigns ? 'is_test_campaign' : '-is_test_campaign';
      const { campaigns, size, error } = await fetchCampaigns({
        query_string: `bool=${test_campaign_query}&page=${page}&population=${population}&sort_by=-created_on&return_only=is_advanced,id,name,sender_name,sender_email,status,schedule,failed_sends_list,created_on,is_test_campaign`
      });
      if (error) {
        toast.error('Unable to fetch campaigns at this time.');
        return;
      }
      dispatch(addManyCampaignsToStore(campaigns));
      setTotalCampaigns(size);
    } catch (e) {
      dispatch(addManyCampaignsToStore([]));
    } finally {
      setLoading(false);
    }
  };

  const handleSearchRequest = async (keys, keyword, page, population = 50) => {
    if (!keys) return;
    try {
      setLoading(true);
      const test_campaign_query = show_test_campaigns ? 'is_test_campaign' : '-is_test_campaign';
      const { campaigns, size } = await searchCampaigns(keys, keyword, {
        query_string: `bool=${test_campaign_query}&page=${page}&population=${population}&sort_by=-created_on&return_only=id,name,sender_name,sender_email,status,schedule,failed_sends_list,created_on,is_test_campaign`
      });
      setTotalCampaigns(size);
      if (page === 0) return dispatch(addCampaignSearchResultsToStore(campaigns));
      dispatch(addManyCampaignsToStore(campaigns));
    } catch (e) {
      dispatch(addManyCampaignsToStore([]));
    } finally {
      setLoading(false);
    }
  };

  const table_actions = (
    <>
      <Button
        icon_name={show_test_campaigns ? 'hide' : 'show'}
        onClick={() => setShowTestCampaigns((val) => !val)}
        text={`${show_test_campaigns ? 'Hide' : 'Show'} test campaigns`}
        type="secondary"
      />
      <Button
        icon_name="add"
        text="Campaign"
        disabled={!permissions['campaign:create']}
        onClick={() => setShowCreationOptions(true)}
      />
    </>
  );

  return (
    <PageContentWrapper>
      {!isMobile && (
        <>
          <CampaignStatBar />
          <Spacer multiple={4} />
        </>
      )}
      <GridRow>
        <GridColumn span={4}>
          {is_mobile_view ? (
            <MobileDatatable
              config={config}
              action={handleDatatableAction}
              onClick={handleItemClick}
              onListModeChange={setSearchMode}
              onDataRequest={handleDataRequest}
              onSearchRequest={handleSearchRequest}
              showHeader
            />
          ) : (
            <WebDatatable
              config={{
                ...config,
                total_count: total_campaigns
              }}
              action={handleDatatableAction}
              onClick={handleItemClick}
              checkbox
              loading_data={loading}
              table_actions={table_actions}
              onDataRequest={handleDataRequest}
              onSearchRequest={handleSearchRequest}
            />
          )}
          <ConfirmationDialog
            title="Delete Campaign"
            message="Are you sure you want to delete this campaign?"
            callback={handleDeleteConfirmation}
            is_open={show_delete_confirmation}
          />
          <ConfirmationDialog
            title="Cancel Campaign"
            message="Are you sure you want to cancel this campaign?"
            callback={handleCancelConfirmation}
            is_open={show_cancel_confirmation}
          />
          <GmModal
            title="Choose creation mode"
            show_modal={show_creation_options}
            onClose={() => setShowCreationOptions(false)}
            bodyClassName={styles.optionsModal}
            show_title
          >
            <OptionsModal options={creationOptions} is_dkim_set={is_dkim_set} />
          </GmModal>
        </GridColumn>
      </GridRow>
    </PageContentWrapper>
  );
};

export default ListCampaigns;
