import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DetailItem } from 'app/shared/info-section/item';
import { BasicInfoSection } from 'app/shared/info-section/basic';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { Icon } from 'assets/icons';
import profile_icon from 'assets/images/profile_icon.png';
import styles from './profile.module.css';
import { setPageTitle } from 'store/actions/header';
import { useUserService } from 'hooks/iam/user';
import { profileValidationConfig } from 'app/shared/utils/form/validation/profile';
import useValidator from 'hooks/validator';
import { Button } from 'app/shared/button';
import { Spacer } from 'app/layouts/generic';
import { SimpleInput } from 'app/shared/input';
import { toast } from 'react-toastify';
import { addDataToStore } from 'store/actions/user-data';
import { useFilesService } from 'hooks/files';

export const InfoDisplay = ({ data = {}, onEditClick = () => {} }) => {
  return (
    <>
      <section
        className={styles.profileTop}
        style={{ backgroundImage: `url(${data?.profile_image})` }}
      >
        <div className={styles.profilePicture}>
          <img src={data?.profile_image || profile_icon} alt="Profile Picture" />

          <div className={styles.editIcon}>
            <Icon name="edit" onClick={onEditClick} />
          </div>
        </div>
      </section>
      <BasicInfoSection>
        <GridColumn>
          <DetailItem title="First Name">{data.firstname}</DetailItem>
        </GridColumn>
        <GridColumn>
          <DetailItem title="Last Name">{data.lastname || 'N/A'}</DetailItem>
        </GridColumn>
        <GridColumn>
          <DetailItem title="Email">{data.email || 'N/A'}</DetailItem>
        </GridColumn>
        <GridColumn>
          <DetailItem title="PHONE NUMBER">{data.phone_number || 'N/A'}</DetailItem>
        </GridColumn>
      </BasicInfoSection>
    </>
  );
};

const UpdationForm = ({ data = {}, onSave = () => {} }) => {
  const [profile_photo, setProfilePhoto] = useState('');
  const { uploadImage } = useFilesService();
  const { updateUserData } = useUserService();
  const [user_data, setUserData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone_number: ''
  });
  const [saving, setSaving] = useState(false);
  const { errors, form_is_valid, validateField } = useValidator(profileValidationConfig);

  useEffect(() => {
    const { firstname, lastname, email, phone_number, profile_image } = data;

    handleChange('firstname', firstname);
    handleChange('lastname', lastname);
    handleChange('email', email);
    handleChange('phone_number', phone_number);
    setProfilePhoto(profile_image);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const submitForm = async () => {
    const { firstname, lastname, email, phone_number } = user_data;

    const form_data = {
      firstname,
      lastname,
      email,
      phone_number,
      profile_image: profile_photo
    };

    setSaving(true);
    const response = await updateUserData(data.id, {
      data: form_data
    });
    setSaving(false);
    if (!response) {
      return;
    }

    toast.success('User updated successfully.');
    onSave({ ...data, ...form_data });
  };

  const handleChange = (field, value) => {
    validateField(field, value);
    setUserData((previous_values) => ({
      ...previous_values,
      [field]: value
    }));
  };

  const handleFileSelection = async (e) => {
    if (!e.target.files) return;
    const selected_file = e.target.files[0];

    if (e.target.files && selected_file.size <= 2 * 1024 * 1024) {
      const formData = new FormData();
      formData.append('image', selected_file);

      uploadImage({
        data: formData,
        headers: {
          'Content-Type': 'application/form-data'
        }
      }).then((payload) => {
        setProfilePhoto(payload.location);
        toast.success('Image Uploaded.');
      });
    } else {
      toast.error('File size exceeds the limit of 2MB');
    }
  };

  const handleDeleteImage = () => {
    setProfilePhoto('');
  };

  return (
    <>
      <section className={styles.profileTop} style={{ backgroundImage: `url(${profile_photo})` }}>
        <div className={styles.UpdateProfilePicture}>
          <img src={profile_photo || profile_icon} alt="Profile Picture" />
          <div className={styles.UpdateEditIcon}>
            <div>
              <input
                id="input-image-upload"
                type="file"
                accept="image/*"
                onChange={handleFileSelection}
                onClick={(e) => (e.target.value = null)}
                style={{ visibility: 'hidden' }}
              />
              <label htmlFor="input-image-upload">
                <Icon name="upload" />
              </label>
              <Icon name="trash" onClick={handleDeleteImage} />
            </div>
          </div>
        </div>
      </section>
      <BasicInfoSection>
        <GridColumn>
          <DetailItem title="First Name">
            <SimpleInput
              input_id="firstname"
              value={user_data.firstname}
              onInput={(value) => handleChange('firstname', value)}
              error={errors.firstname}
            />
          </DetailItem>
        </GridColumn>
        <GridColumn>
          <DetailItem title="Last Name">
            <SimpleInput
              input_id="lastname"
              value={user_data.lastname}
              onInput={(value) => handleChange('lastname', value)}
              error={errors.lastname}
            />
          </DetailItem>
        </GridColumn>
        <GridColumn>
          <DetailItem title="Email Address">
            <SimpleInput
              input_id="email-address"
              value={user_data.email}
              onInput={(value) => handleChange('email', value)}
              error={errors.email}
            />
          </DetailItem>
        </GridColumn>
        <GridColumn>
          <DetailItem title="Phone">
            <SimpleInput
              input_id="phone"
              value={user_data.phone_number}
              onInput={(value) => handleChange('phone_number', value)}
            />
          </DetailItem>
        </GridColumn>
        <Spacer multiple={2} />
        <GridRow>
          <GridColumn span={4}>
            <Button
              onClick={submitForm}
              text="Save"
              loading={saving}
              disabled={!form_is_valid || saving}
            />
          </GridColumn>
        </GridRow>
      </BasicInfoSection>
    </>
  );
};

export const UserProfileSetting = () => {
  const dispatch = useDispatch();
  const user_data = useSelector((state) => state.user_data);
  const [mode, setMode] = useState('view');

  useEffect(() => {
    dispatch(
      setPageTitle([
        { title: 'Settings', path: '/settings' },
        { title: 'Profile', path: '/settings/profile' }
      ])
    );
  }, []);

  const handleSave = (user_data = {}) => {
    dispatch(addDataToStore(user_data));
    setMode(() => 'view');
  };

  return mode === 'view' ? (
    <InfoDisplay data={user_data} onEditClick={() => setMode('edit')} />
  ) : (
    <UpdationForm data={user_data} onSave={handleSave} />
  );
};
