import cx from 'classnames';
import { Icon } from 'assets/icons';
import { TreeContext } from 'contexts/automation';
import { useResourceQuery } from 'hooks/automation/resources';
import { useAutomationStepService } from 'hooks/automation/steps';
import React, { useContext, useEffect, useState } from 'react';
import { Handle, Position } from 'reactflow';
import { serializeAction } from './serializer';
import styles from './widget.module.css';
import { toast } from 'react-toastify';

export const AutomationActionWidget = (props) => {
  const { id: source_handle_id, isConnectable, data } = props;
  const { fetchResources } = useResourceQuery();
  const { deleteStep } = useAutomationStepService();
  const { setWidgetToCreate, toggleStepModal } = useContext(TreeContext);

  const [resource_name, setResourceName] = useState('');

  useEffect(() => {
    if (!data || !data.target) return;
    const [resource_class, resource_id] = data.target.split(':');
    fetchResources(resource_class, '', {
      query_string: `id=${resource_id}&return_only=name,title,link`
    }).then(({ resources, error }) => {
      if (error) return toast.error(error);

      const [resource] = resources;
      setResourceName(() => resource.name || resource.title || resource.link);
    });
  }, [data]);

  const handleDelete = async (e) => {
    e.stopPropagation();
    await deleteStep(data.id);
  };

  const handleEdit = (e) => {
    e.stopPropagation();
    if (data.children.length > 0) return;
    setWidgetToCreate('action');
    toggleStepModal({
      template_id: data.template_id,
      parents: data.parents || [],
      show: true,
      mode: 'edit',
      data
    });
  };

  return (
    <div className={styles.widget}>
      <Handle type="target" position={Position.Top} isConnectable={isConnectable} />
      <div className={cx(styles.wrapper, styles.green)}>
        <div className={styles.content} onClick={handleEdit}>
          <span>{serializeAction(data.effect, resource_name)}</span>
          <span className={styles.icon}>
            <Icon name="trash" onClick={handleDelete} />
          </span>
        </div>
      </div>
      <span id={styles.vertLine} />
      <span className={styles.addBtn}>
        <Icon
          name="add"
          onClick={() => {
            setWidgetToCreate(() => '');
            toggleStepModal({
              template_id: data.template_id,
              parents: [data.id],
              source: '-',
              show: true,
              source_widget: 'action'
            });
          }}
        />
      </span>
      <Handle
        type="source"
        position={Position.Bottom}
        id={source_handle_id}
        isConnectable={isConnectable}
      />
    </div>
  );
};
