import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as AudienceService from 'services/audience';
import { addOneAudienceToStore, updateAudienceInStore } from 'store/actions/audience';
import { Button } from 'app/shared/button';
import { SimpleInput } from 'app/shared/input';
import { MultiSelect } from 'app/shared/select';
import { TextArea } from 'app/shared/input/textarea';
import { Checkbox } from 'app/shared/input/checkbox';
import { DetailItem } from 'app/shared/info-section/item';
import useValidator from 'hooks/validator';
import { audienceValidationConfig } from 'app/shared/utils/form/validation/audience';
import styles from './create.module.css';

const CreateMailingList = ({ closeModal, current_test_audience_id }) => {
  const dispatch = useDispatch();
  const { token, id } = useSelector((state) => state.user_data);
  const { errors, validateField, form_is_valid } = useValidator(audienceValidationConfig);
  const [audiences, setAudiences] = useState([]);
  const [selected_audience_to_add_sub, setSelectedAudienceToAddSub] = useState([]);
  const [selected_audience_to_remove_sub, setSelectedAudienceToRemoveSub] = useState([]);
  const [selected_audience_to_add_unsub, setSelectedAudienceToAddUnSub] = useState([]);
  const [selected_audience_to_remove_unsub, setSelectedAudienceToRemoveUnSub] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form_data, setFormData] = useState({});

  useEffect(() => {
    AudienceService.read({ token, query_string: 'return_only=name,id' }).then((response) => {
      const {
        error,
        payload: { data }
      } = response;
      if (error) return;

      setAudiences(data);
    });
  }, [token]);

  const clearForm = () => {
    setFormData({});
  };

  const handleInputChange = (key, value = '') => {
    validateField(key, value);
    setFormData((curr_data) => ({
      ...curr_data,
      [key]: value
    }));
  };

  const submit = async () => {
    const data = {
      ...form_data,
      tenant_id: id,
      on_subscribe: {
        add: selected_audience_to_add_sub.map((audience) => audience.value),
        remove: selected_audience_to_remove_sub.map((audience) => audience.value)
      },
      on_unsubscribe: {
        add: selected_audience_to_add_unsub.map((audience) => audience.value),
        remove: selected_audience_to_remove_unsub.map((audience) => audience.value)
      }
    };

    setLoading(true);
    const { error, payload } = await AudienceService.create({ data, token });
    if (error) {
      toast.error(error);
      setLoading(false);
      return;
    }

    setLoading(false);
    toast.success('Audience created.');
    dispatch(addOneAudienceToStore(payload));
    if (
      current_test_audience_id &&
      current_test_audience_id !== payload.id &&
      data.is_test_audience
    ) {
      dispatch(updateAudienceInStore({ is_test_audience: false, id: current_test_audience_id }));
    }
    clearForm();
    closeModal();
  };

  return (
    <div className={styles.wrapper}>
      <DetailItem title="Audience name">
        <SimpleInput
          required
          value={form_data.name}
          onInput={(v) => handleInputChange('name', v)}
          error={errors.name}
        />
      </DetailItem>
      <DetailItem title="Description">
        <TextArea
          className="gm-input"
          value={form_data.description}
          onChange={(e) => e}
          onInput={(v) => handleInputChange('description', v)}
        />
      </DetailItem>
      <Checkbox
        checked={form_data.is_test_audience}
        input_id="is_test_audience"
        label="Use as test audience"
        onClick={() => handleInputChange('is_test_audience', !form_data?.is_test_audience)}
      />
      <p className="pb-2 pt-4 border-bottom">When a contact subscribes to this audience:</p>
      <div className="grid cols-2">
        <div>
          <DetailItem title="Add contact to:">
            <MultiSelect
              options={audiences.map((audience) => ({
                label: audience.name,
                value: audience.id
              }))}
              onChange={setSelectedAudienceToAddSub}
              value={selected_audience_to_add_sub}
              label=""
              isMulti
            />
          </DetailItem>
        </div>
        <div>
          <DetailItem title="Remove contact from:">
            <MultiSelect
              options={audiences.map((audience) => ({
                label: audience.name,
                value: audience.id
              }))}
              onChange={setSelectedAudienceToRemoveSub}
              value={selected_audience_to_remove_sub}
              label=""
              isMulti
            />
          </DetailItem>
        </div>
      </div>
      <p className="pb-2 pt-4 border-bottom">When a contact unsubscribes from this audience:</p>
      <div className="grid cols-2">
        <div>
          <DetailItem title="Add contact to:">
            <MultiSelect
              options={audiences.map((audience) => ({
                label: audience.name,
                value: audience.id
              }))}
              onChange={setSelectedAudienceToAddUnSub}
              value={selected_audience_to_add_unsub}
              label=""
              isMulti
            />
          </DetailItem>
        </div>
        <div>
          <DetailItem title="Remove contact from:">
            <MultiSelect
              options={audiences.map((audience) => ({
                label: audience.name,
                value: audience.id
              }))}
              onChange={setSelectedAudienceToRemoveUnSub}
              value={selected_audience_to_remove_unsub}
              label=""
              isMulti
            />
          </DetailItem>
        </div>
      </div>
      <Button
        text="Save"
        onClick={submit}
        className="float-right w-50 mt-4 mb-4"
        disabled={!form_is_valid || loading}
        loading={loading}
      />
    </div>
  );
};

export default CreateMailingList;
