import React, { useState, useEffect, useContext } from 'react';
import { DetailItem } from 'app/shared/info-section/item';
import { BasicInfoSection } from 'app/shared/info-section/basic';
import { Icon } from 'assets/icons';
import { useBillingService } from 'hooks/billing/billing';
import { countries } from 'app/data/countries';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { SimpleInput } from 'app/shared/input';
import { toast } from 'react-toastify';
import { Button } from 'app/shared/button';
import { MultiSelect } from 'app/shared/select';
import { Spacer } from 'app/layouts/generic';
import useValidator from 'hooks/validator';
import { billingInfomationValidationConfig } from 'app/shared/utils/form/validation/billing';
import { PermissionsContext } from 'contexts/permissions';

export const Billinginformation = () => {
  const [mode, setMode] = useState('view');
  const [billing_info, setBillingInfo] = useState({});
  const { fetchBillingInfos } = useBillingService();

  useEffect(() => {
    fetchBillingInfos().then(({ billing_infos, error }) => {
      if (error) return toast.error(error);
      setBillingInfo(billing_infos[0]);
    });
  }, []);

  const handleSave = (data) => {
    setMode(() => 'view');
    setBillingInfo(data);
  };

  return mode === 'view' ? (
    <BillingDisplay data={billing_info} onEditClick={() => setMode('edit')} />
  ) : (
    <EditBillingDisplay data={billing_info} onSave={handleSave} onCancel={() => setMode('view')} />
  );
};

export const BillingDisplay = ({ data = {}, onEditClick = () => {} }) => {
  return (
    <BasicInfoSection title="Billing Information" icon={<Icon name="edit" onClick={onEditClick} />}>
      <DetailItem title="Email">{data.email || 'N/A'}</DetailItem>
      <DetailItem title="Billing Address">{data.address || 'N/A'}</DetailItem>
    </BasicInfoSection>
  );
};

const EditBillingDisplay = ({ data = {}, onSave = () => {}, onCancel = () => {} }) => {
  const { errors, form_is_valid, validateField } = useValidator(billingInfomationValidationConfig);

  const { createBillingInfo } = useBillingService();
  const [billing_data, setBillintInfo] = useState({});
  const [saving, setSaving] = useState(false);
  const { permissions } = useContext(PermissionsContext);

  useEffect(() => {
    const { state, email, address, zip } = data;

    handleChange('state', state);
    handleChange('email', email);
    handleChange('address', address);
    handleChange('zip', zip);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitForm = async () => {
    const { state, country, email, address, zip } = billing_data;

    const form_data = {
      address,
      state,
      email,
      zip,
      country: country?.value
    };

    setSaving(true);
    const response = await createBillingInfo({
      data: form_data
    });
    setSaving(false);
    if (!response) {
      return;
    }

    toast.success('Billing updated successfully.');
    onSave({ ...data, ...form_data });
  };

  const handleChange = (field, value) => {
    validateField(field, value);
    setBillintInfo((previous_values) => ({
      ...previous_values,
      [field]: value
    }));
  };

  return (
    <BasicInfoSection title="Billing Information">
      <GridColumn>
        <DetailItem title="EMAIL ADDRESS">
          <SimpleInput
            input_id="email"
            value={billing_data.email}
            onInput={(value) => handleChange('email', value)}
            error={errors.email}
          />
        </DetailItem>
      </GridColumn>
      <GridColumn>
        <DetailItem title="STREET ADDRESS">
          <SimpleInput
            input_id="address"
            value={billing_data.address}
            onInput={(value) => handleChange('address', value)}
            error={errors.address}
          />
        </DetailItem>
      </GridColumn>

      <GridRow num_of_columns={3}>
        <GridColumn>
          <DetailItem title="STATE">
            <SimpleInput
              input_id="state"
              value={billing_data.state}
              onInput={(value) => handleChange('state', value)}
              error={errors.state}
            />
          </DetailItem>
        </GridColumn>
        <GridColumn>
          <DetailItem title="ZIP CODE">
            <SimpleInput
              type="number"
              input_id="zip"
              value={billing_data.zip}
              onInput={(value) => handleChange('zip', value)}
            />
          </DetailItem>
        </GridColumn>
        <GridColumn>
          <DetailItem title="COUNTRY">
            <MultiSelect
              options={countries.map((country) => ({
                label: country.name,
                value: country.code
              }))}
              onChange={(value) => handleChange('country', value)}
              value={billing_data.country}
              required
              isMulti={false}
            />
          </DetailItem>
        </GridColumn>
      </GridRow>
      <Spacer multiple={2} />
      <GridRow num_of_columns={3}>
        <GridColumn />
        <GridColumn>
          <Button onClick={onCancel} text="Cancel" type="secondary" />
        </GridColumn>
        <GridColumn>
          <Button
            onClick={submitForm}
            text="Save"
            loading={saving}
            disabled={!form_is_valid || saving || !permissions['billing_config:update']}
          />
        </GridColumn>
      </GridRow>
    </BasicInfoSection>
  );
};
