import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as walletService from 'services/wallet';
import { addWallet, updateWallet } from 'store/actions/wallet';

export const useWallet = () => {
  const dispatch = useDispatch();
  const { tenant_id, token } = useSelector((state) => state.user_data);

  const checkBalanceSufficience = async () => {
    const { error, payload } = await walletService.checkCreditsLevel({ token });
    if (error) return toast.error(error);
    const { is_sufficient } = payload;
    return is_sufficient;
  };

  const createWallet = async () => {
    const { error, payload } = await walletService.create({ data: { tenant_id }, token });
    if (error) return toast.error('Could not retrieve wallet. Please reload page');
    return dispatch(
      addWallet({
        ...payload,
        balance: payload.balance ? payload.balance.toFixed(2) : 0
      })
    );
  };

  const createVirtualAccount = async (data) => {
    const { error, payload } = await walletService.createVirtualAccount({ data, token });
    if (error) {
      toast.error(error);
      return null;
    }

    updateWalletInStore({ virtual_account: { ...payload } });
    toast.success('Virtual account created successfully');
    return payload;
  };

  const fetchWallet = async () => {
    const query_string = `tenant_id=${tenant_id}`;
    const { error, payload } = await walletService.read({ token, query_string });
    if (error) return toast.error(error);

    const {
      data: [wallet]
    } = payload;

    if (wallet && wallet.id) {
      return dispatch(
        addWallet({
          ...wallet,
          balance: wallet.balance ? wallet.balance.toFixed(2) : 0
        })
      );
    }

    createWallet();
  };

  const formatBalance = (count = 0) => {
    const integer_value = Number(count);

    if (integer_value > 9999 && integer_value <= 999999) {
      return `${(integer_value / 1000).toFixed(2)}K`;
    }

    if (integer_value > 999999) {
      return `${(integer_value / 1000000).toFixed(2)}M`;
    }

    return integer_value;
  };

  const updateWalletInStore = (data) => {
    dispatch(updateWallet({ ...data }));
  };

  return {
    checkBalanceSufficience,
    createVirtualAccount,
    createWallet,
    fetchWallet,
    formatBalance,
    updateWalletInStore
  };
};
