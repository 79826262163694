import { table_config } from './helper';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MobileDatatable from 'app/shared/datatable/mobile/datatable';
import WebDataTable from 'app/shared/datatable/web/datatable';

import { addManyTutorialsToStore, addTutorialSearchResultsToStore } from 'store/actions/tutorial';
import { setPageTitle } from 'store/actions/header';
import { useTutorialService } from 'hooks/files/tutorial';
import { toast } from 'react-toastify';

export const TutorialListPage = () => {
  const { is_mobile_view } = useSelector((state) => state.metadata);
  const tutorials_in_store = useSelector((state) => state.tutorials);

  const dispatch = useDispatch();
  const { fetchTutorials, searchTutorials } = useTutorialService();

  const [items, setItems] = useState([]);
  const [is_search_mode, setSearchMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tutorial_count, setTutorialCount] = useState(0);

  useEffect(() => {
    dispatch(setPageTitle([{ title: 'Tutorials', path: '.' }]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setItems(Object.values(tutorials_in_store));
  }, [tutorials_in_store]);

  const handleDatatableAction = (action) => {
    const { name, type, data } = action;

    if (type.toLowerCase() === 'single') {
      const { url } = data;
      switch (name.toLowerCase()) {
        case 'open':
          window.open(url, '_blank');
          break;
        default:
      }
    }
  };

  const handleItemClick = (data) => {
    const { url } = data;
    window.open(url, '_blank');
  };

  const handleDataRequest = async (page) => {
    try {
      const { tutorials, size, error } = await fetchTutorials({
        query_string: `sort_by=-created_on&page=${page}&population=50`
      });
      if (error) return toast.error(error);
      setTutorialCount(size);
      dispatch(addManyTutorialsToStore(tutorials));
    } catch (e) {
      dispatch(addManyTutorialsToStore([]));
    }
  };

  const handleSearchRequest = async (keys, keyword, page) => {
    if (!keys) return;
    try {
      setLoading(true);
      const { tutorials, size } = await searchTutorials(keys, keyword, {
        query_string: `sort_by=-created_on&page=${page}&population=50`
      });
      if (page === 0) {
        return dispatch(addTutorialSearchResultsToStore(tutorials));
      }

      dispatch(addManyTutorialsToStore(tutorials));
      setTutorialCount(size);
    } catch (e) {
      dispatch(addManyTutorialsToStore([]));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {is_mobile_view ? (
        <MobileDatatable
          config={{
            ...table_config,
            is_search_mode,
            items,
            total_count: tutorial_count
          }}
          action={handleDatatableAction}
          onClick={handleItemClick}
          onListModeChange={setSearchMode}
          onDataRequest={handleDataRequest}
          onSearchRequest={handleSearchRequest}
        />
      ) : (
        <div>
          <WebDataTable
            config={{
              ...table_config,
              is_search_mode,
              items,
              total_count: tutorial_count
            }}
            action={handleDatatableAction}
            onClick={handleItemClick}
            checkbox
            loading_data={loading}
            onDataRequest={handleDataRequest}
            onSearchRequest={handleSearchRequest}
          />
        </div>
      )}
    </div>
  );
};
